import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'navigation_type',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.decoration.navigationType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.decoration.naviNameCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.decoration.naviNameEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'subtitle',
      name: 'input',
      label: i18n.t('form.decoration.subtitlecn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'subtitle_en',
      name: 'input',
      label: i18n.t('form.decoration.subtitleen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'model',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.decoration.navigationButton'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'url',
      name: 'input',
      label: i18n.t('form.decoration.CustomUrl'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'model',
        visibleValue: 'other'
      }
    }, {
      span: 24,
      prop: 'remote_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.decoration.image'),
      Object: {
        limit: 1
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.decoration.ranking'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
